import React from 'react';
import styled from 'styled-components';
import EmailIcon from '@mui/icons-material/Email';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 1;
  align-items: center;
  @media (max-width: 960px) {
    padding: 0px;
  }
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 1350px;
  padding: 0px 0px 80px 0px;
  gap: 12px;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

const Title = styled.div`
  font-size: 42px;
  text-align: center;
  font-weight: 600;
  margin-top: 20px;
  color: ${({ theme }) => theme.text_primary};
  @media (max-width: 768px) {
    margin-top: 12px;
    font-size: 32px;
  }
`;

const Desc = styled.div`
  font-size: 18px;
  text-align: center;
  max-width: 600px;
  color: ${({ theme }) => theme.text_secondary};
  @media (max-width: 768px) {
    margin-top: 12px;
    font-size: 16px;
  }
`;

const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  margin-top: 20px;
`;

const InfoItem = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  color: ${({ theme }) => theme.text_primary};
`;

const Link = styled.a`
  color: white; // You might want to adjust the color based on your theme
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const Icon = styled.span`
  margin-right: 10px;
  display: flex;
  color: ${({ theme }) => theme.primary}; // Adjust the color based on your theme
`;

const Button = styled.button`
  background: ${({ theme }) => theme.primary};
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  hover: 0.3s;
  text-decoration: none;
  margin-left: 10px;
  &:hover {
    background: ${({ theme }) => theme.secondary};
  }
`;

const Contact = () => {
  return (
    <Container>
      <Wrapper>
        <Title>Contato</Title>
        <Desc>Abaixo algumas maneiras de se conectar comigo.</Desc>
        <ContactInfo>
          <InfoItem>
            <Icon>
              <EmailIcon />
            </Icon>
            guilhermedsmarta@gmail.com
          </InfoItem>
          <InfoItem>
            <Icon>
              <WhatsAppIcon />
            </Icon>
            +55 51 986515196
            <Button as="a" href='https://wa.me/5551986515196'>Fale comigo</Button>
          </InfoItem>
          <InfoItem>
            <Icon>
              <LinkedInIcon />
            </Icon>
            <Link href='https://www.linkedin.com/in/guilherme-marta-87458033b/'>Linkedin</Link>
          </InfoItem>
        </ContactInfo>
      </Wrapper>
    </Container>
  );
};

export default Contact;
